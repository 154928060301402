.map-wrapper {
  display: flex;
  height: 900px;
  background-image: linear-gradient(272.31deg, rgba(0, 0, 0, 0.84) -6.82%, rgba(0, 0, 0, 0.837986) 87.28%), url('assets/map/map-back-1440.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
}

.map-title {
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  margin-top: 72px;

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #E3E5EC;
  }

  h3 {
    font-weight: bold;
    font-size: 36px;
    line-height: 56px;
    color: #FFFFFF;
  }
}

.map {
  width: 100%;
  height: 623px;
  padding: 108px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 1200px;
  }
}


@media (max-width: 1250px) {
  .map-wrapper {
    display: flex;
    height: 900px;
    background-image: linear-gradient(272.31deg, rgba(0, 0, 0, 0.84) -6.82%, rgba(0, 0, 0, 0.837986) 87.28%), url('assets/map/map-back-1440.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .content-wrapper {
    max-width: 993px;
  }

  .map-title {
    margin-left: 0;
    margin-top: 71px;
    padding: 0 80px;
  }

  .map {
    height: 489px;
    padding-top: 249px;
    margin-left: 2px;

    img {
      max-width: 993px;
    }
  }
}

@media (max-width: 800px) {
  .map-wrapper {
    height: 600px;
  }
  .content-wrapper {
    max-width: 993px;
  }

  .map-title {
    margin-left: 0;
    margin-top: 53px;
    padding: 0 40px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 42px;
    }
  }

  .map {
    height: 338px;
    padding-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 659px;
    }
  }
}

@media (max-width: 420px) {
  .map-wrapper {
    height: 640px;
  }
  .content-wrapper {
    max-width: 993px;
  }

  .map-title {
    padding: 0 20px;
    text-align: center;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 42px;
    }

    h4 {
      margin-bottom: 8px;
    }
  }

  .map {
    height: 338px;
    padding-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 659px;
    }
  }
}
