.header {
  .header-title-wrapper {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 80vw;

    .header-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 76px;
      line-height: 86px;
      color: #FFFFFF;
      margin-bottom: 24px;
      overflow: hidden;
    }

    .lg {
      display: none;
    }

    .xs {
      display: none;
    }

    .header-subtitle {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #F5F6F8;
      opacity: 0.8;
      margin: 24px 0 32px;
    }

    .header-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 214px;
      height: 48px;
      background: #F4D13A;
      border: 2px solid #F4D13A;
      border-radius: 4px;
      cursor: pointer;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #282A32;
    }
  }

  .header-top-block {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100%;
    padding: 0 160px;
  }

  .header-logo {
    width: 148px;
    height: 68px;
    margin-top: 28px;
    background-image: url('assets/header/logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .header-contacts {
    display: flex;
    margin-top: 38px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    align-items: flex-start;
    color: #FFFFFF;

    a, a:hover, a:focus, a:active {
      font-family: 'Montserrat', sans-serif;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

.header-email-wrapper {
  display: flex;
}
.header-phone-wrapper {
  display: flex;
  margin-left: 24px;
}

  .header-phone {
    display: flex;
    flex-direction: column;

    a {
      margin-bottom: 4px;
    }
  }

  .mail-icon, .phone-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-top: -2px;
  }
}

//slider

.slide-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-image: linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%), url('assets/header/ship-mobile.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header {
  .swiper-pagination-bullet {
    background: #efefef6e;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #f4d13a;
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }

  .swiper-lazy {
    background-color: #070708e0;
  }
  .swiper-lazy-preloader-white {
    --swiper-preloader-color: #f4d13a;
  }
}

@media (max-width: 1250px) {
  .header {
    .header-title-wrapper {
      max-width: 56vw;

      .xl {
        display: none;
      }

      .xs {
        display: none;
      }

      .lg {
        display: inline;
        white-space: nowrap;
      }
    }

    .header-top-block {
      padding: 0 80px;
    }
  }
}

@media (max-width: 800px){
  .header {
    justify-content: center;

    .header-top-block {
      justify-content: center;
    }
    .header-title-wrapper {
      .header-subtitle {
        text-align: center;
        margin: 82px 0 32px;
        margin-bottom: 5vh;
      }

      .header-title {
        align-items: center;
      }


      .xl {
        display: none;
      }

      .xs {
        display: inline;
      }

      .lg {
        display: none;
      }
    }

    .header-contacts {
      display: flex;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      bottom: -80vh;
    }

    .header-phone {
      padding-right: 30px;
    }
    .mail-icon,
    .phone-icon {
      display: none;
    }
  }
}



@media (max-width: 680px){
  .header {
    .header-title-wrapper {
      .header-title {
        font-size: 3.6em;
        line-height: 60px;
      }
    }
  }
}

@media (max-height: 650px){
  .header {
    .header-title-wrapper {
      .header-title {
        font-size: 38px;
        line-height: 48px;
      }

      .header-subtitle {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .header-contacts {

      a, a:hover, a:focus, a:active {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}
@media (max-width: 520px){
  .header {
    .header-title-wrapper {
      //.header-title {
      //  font-size: 3.6em;
      //  margin-bottom: 85px;
      //}

      .header-subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .header-logo {
      width: 96px;
      height: 44px;
      margin-top: 28px;
      background-image: url('assets/header/icons/TGK-mobile.png');
    }
  }
}

@media (max-width: 520px){
  .header {
    .header-title-wrapper {
      .header-title {
        font-size: 38px;
        line-height: 48px;
      }

      .header-subtitle {
        margin-top: 24px;
      }
    }
  }
}


//@media (max-width: 520px){
//  .header {
//    .header-title-wrapper {
//      .header-title {
//        font-size: 2.6em;
//        margin-bottom: 85px;
//      }
//    }
//  }
//}
//
//@media (max-width: 430px){
//  .header {
//    .header-title-wrapper {
//      .header-title {
//        font-size: 2.6em;
//      }
//    }
//  }
//}


