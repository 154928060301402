.processes-wrapper {
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1120px;
    margin: auto;
  }

  .processes-title {
    margin-top: 114px;
    margin-bottom: 48px;

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #7B7F8E;
      margin-bottom: 14px;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 56px;
      color: #282A32;
    }
  }

  .processes-block {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    & li:first-child {
      margin-left: 0;
    }

    .card-wrapper {
      display: flex;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 140px;
      height: 282px;

      p {
        font-size: 16px;
        line-height: 24px;
        color: #282A32;
        text-align: center;
      }

      span {
        padding-top: 24px;
        width: 100%;
        border-top: 1px solid #F4D13A;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #282A32;
      }
    }

    .processes-icon {
      width: 90px;
      height: 90px;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 4px;
    }

    .order {
      background-image: url('assets/processes/svg/Order.svg');
    }

    .accounting {
      background-image: url('assets/processes/svg/Accounting.svg');
    }

    .contract {
      background-image: url('assets/processes/svg/Contract.svg');
    }

    .pay {
      background-image: url('assets/processes/svg/Pay.svg');
    }

    .ship {
      background-image: url('assets/processes/svg/Cargo-ship.svg');
    }

    .cube {
      background-image: url('assets/processes/svg/Get Delivery_2.svg');
    }
  }

  .tgk-label {
    width: 100%;
    height: 86px;
    margin-top: 68px;
    margin-bottom: 132px;
    background-image: url('assets/processes/svg/TGK LOGISTIC.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .arrow {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 44px;
    height: 100%;
    margin: 43px 6px 0;

    img {
      width: 44px;
      height: 14px;
    }
  }

  .icons-top,
  .icons-bottom {
    display: flex;
  }
}

@media (max-width: 1250px) {
  .processes-wrapper {
    padding: 0 80px;

    .content-wrapper {
      max-width: 993px;
    }

    .processes-title {
      margin-top: 114px;
      margin-bottom: 48px;
    }

    .processes-block {
      .card {
        width: 118px;
      }
    }

    .tgk-label {
      background-image: url('assets/processes/svg/TGK LOGISTIC.svg');
      height: 77px;
      margin-top: 66px;
      margin-bottom: 134px;
    }
  }
}

@media (max-width: 1152px) {
  .processes-wrapper {
    .content-wrapper {
      max-width: 993px;
    }

    .processes-title {
      margin-top: 114px;
      margin-bottom: 48px;
    }

    .processes-block {
      flex-direction: column;

      .card {
        width: 100%;
        max-width: 260px;
      }
    }

    .tgk-label {
      margin-bottom: 122px;
    }

    .icons-top,
    .icons-bottom {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    .icons-top {
      margin-bottom: 65px;
    }
  }
}

@media (max-width: 800px) {
  .processes-wrapper {
    margin-bottom: 17px;

    .content-wrapper {
      max-width: 993px;
    }

    .processes-title {
      margin-top: 64px;
      margin-bottom: -20px;
      text-align: center;

      h3 {
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 43px;
      }
    }

    .processes-block {
      flex-direction: column;
      margin-bottom: 90px;

      .card {
        width: 100%;
        max-width: 260px;
      }

      .card-wrapper {
        flex-direction: column;
        margin-bottom: 38px;
      }
    }

    .tgk-label {
      display: none;
    }

    .icons-top,
    .icons-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .icons-top {
      margin-bottom: 65px;
    }

    .arrow{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(90deg);
      height: 23px;
    }
  }
}

@media (max-width: 800px) {
  .processes-wrapper {
    margin-bottom: 17px;
    padding: 0 24px;

    .content-wrapper {
      max-width: 993px;
    }

    .processes-title {
      margin-top: 64px;
      margin-bottom: -20px;
      text-align: center;

      h4 {
        margin-bottom: 7px;
      }
    }

    .processes-block {
      flex-direction: column;
      margin-bottom: 90px;

      .card {
        width: 100%;
        max-width: 260px;
      }

      .card-wrapper {
        flex-direction: column;
        margin-bottom: 38px;
      }
    }

    .tgk-label {
      display: none;
    }

    .icons-top,
    .icons-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .icons-top {
      margin-bottom: 65px;
    }

    .arrow{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(90deg);
      height: 23px;
    }
  }
}
