@import "src/styles/header";
@import "src/styles/directions";
@import "src/styles/advantages";
@import "src/styles/processes";
@import "src/styles/map";
@import "src/styles/footer";
@import "src/styles/form";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.mobile {
  display: none;
}

@media (max-width: 780px){
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}


