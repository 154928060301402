.directions-wrapper {
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  padding: 0 80px;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1120px;
    margin: auto;
    margin-top: 97px;
  }

  .directions-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-bottom: 48px;
    margin-top: 3px;

    height: 100%;
    overflow-x: hidden;
    padding-top: 15px;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 13px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 56px;
    }
  }

  .top-block-cards, .bottom-block-cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
    //margin-left: 8px;
  }

  .top-block-cards {
    margin-bottom: 44px;
  }

  .bottom-block-cards {
    margin-bottom: 112px;
  }

  ul > li {
    margin-right: 36px;
  }

  ul li:last-child {
    margin-right: 0;
  }

  li {
    span {
      display: flex;
      justify-content: center;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border: 1px solid #E3E5EC;
    border-radius: 4px;
    padding: 32px 36px;
    width: 254px;;

    .icon {
      background-image: url('assets/directions/Auto.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-color: #36A9B7;
      width: 85.33px;
      height: 85.33px;
      border-radius: 50%;
      margin: 4px auto 25px;
    }

    .auto {
      background-image: url('assets/directions/Auto.svg');
    }

    .mix {
      background-image: url('assets/directions/Mix.svg');
    }

    .el {
      background-image: url('assets/directions/Oversized_3.svg');
    }

    .war {
      background-image: url('assets/directions/Danger.svg');
      margin-top: -26px;
    }

    .fly-car {
      background-image: url('assets/directions/Modal Delivery.svg');
      margin-top: 2px;
    }

    .fly {
      background-image: url('assets/directions/Plane.svg');
      margin-top: -31px;
    }

    .train {
      background-image: url('assets/directions/Train.svg');
      margin-top: 0px;
    }

    .ship {
      background-image: url('assets/directions/Ship.svg');
      margin-top: -30px;
    }

    .text {
      display: flex;
      flex-direction: column;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
    }
  }
}

.cards-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1250px) {
  .directions-wrapper {
    .content-wrapper {
      max-width: 993px;
    }

    ul > li {
      margin-right: 36px;
    }

    .card {
      padding: 30px 21px;
      width: 221px;
    }
  }
}

@media (max-width: 1152px) {
  .directions-wrapper {
    display: flex;
    background-color: #FFFFFF;
    width: 100%;
    padding: 0 80px;

    .content-wrapper {
      max-width: 992px;
    }

    .cards-wrapper {
      flex-direction: initial;
      align-items: baseline;
    }

    .top-block-cards {
      margin-right: 36px;
    }

    .bottom-block-cards {
      margin-bottom: 65px;
    }

    .card {
      width: 100%;
      margin-bottom: 48px;
      height: 270px;
    }

    li {
      span {
        display: inline;
      }
    }
  }
  .directions-wrapper {
    .top-block-cards, .bottom-block-cards {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      //margin-left: 8px;
    }
  }
}

@media (max-width: 800px) {
  .directions-wrapper {
    padding: 0 40px;

    .card {
      padding: 0;
      margin-top: 0;

      .text {
        display: inline;
      }
    }

    .directions-title {
      h4, h3 {
        margin-top: -2px;
        text-align: center;
      }

      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 42px;
      }

      h4 {
        margin-bottom: 19px;
      }
    }

    .cards-wrapper {
      flex-direction: column;
    }

    .top-block-cards {
      margin-bottom: 0;
    }

    ul > li {
      margin-right: 0;
    }

    .top-block-cards {
      margin-right: 0;
    }

    .content-wrapper {
      margin-top: 49px;
    }
  }

  &li span {
    display: inline;
  }

  .card .auto {
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: -27px;
  }
}

@media (max-width: 520px) {
  .directions-wrapper {
    padding: 0 24px;

    .card {
      padding: 0;
      margin-top: 0;

      .icon {
        margin: -22px auto 25px;
      }

      .text {
        display: inline;
      }
    }

    .directions-title {
      h4, h3 {
        margin-bottom: 48px;
        margin-top: -2px;
        text-align: center;
      }

      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 42px;
        margin-bottom: 0;
      }

      h4 {
        margin-bottom: 19px;
      }
    }

    .cards-wrapper {
      flex-direction: column;
    }

    .top-block-cards {
      margin-bottom: 0;
    }

    ul > li {
      margin-right: 0;
    }

    ul > li > p {
      padding: 10px;
    }

    .top-block-cards {
      margin-right: 0;
    }

    .content-wrapper {
      margin-top: 64px;
    }
  }

  &li span {
    display: inline;
  }

  .card .auto {
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: -27px;
  }
}
