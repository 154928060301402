.advantages-wrapper {
  display: flex;
  background-color: #F5F6F8;
  width: 100%;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1120px;
    margin: auto;
  }

  .advantages-title {
    margin-top: 48px;

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #7B7F8E;
      margin-bottom: 14px;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 56px;
      color: #282A32;
    }
  }

  .advantages-context {
    display: flex;
    justify-content: center;
    margin-top: 44px;
    margin-bottom: 1px;
    //margin-left: 8px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      margin-bottom: 51px;
      //margin-bottom: 32px;
    }

    p {
      padding-left: 56px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .control {
    margin-bottom: 10px;
  }

  .label {
    display: flex;
    align-items: flex-start;
    //margin-bottom: 18px;
    margin-bottom: 12px;

    & > span {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      color: #282A32;
    }
  }
  .advantages-icon {
    min-width: 32px;
    min-height: 32px;
    margin-right: 24px;
    background-image: url('assets/advantages/Check.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .advantages-left-block {
    display: flex;
    flex-direction: column;
    margin-right: 64px;
  }
  .advantages-right-block {
    display: flex;
    flex-direction: column;
    margin-right: -8px;
    margin-top: 3px;

    .advantages-img {
      width: 528px;
      height: 274px;
      //margin-bottom: 48px;
      margin-bottom: 32px;
      background-image: url('assets/advantages/hands.png');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

@media (max-width: 1250px) {
  .advantages-wrapper {
    padding: 0 80px;

    ul > li {
      margin-bottom: 34px;
    }
    .content-wrapper {
      max-width: 993px;
    }

    .advantages-title {
      margin-top: 56px;
    }
    .advantages-right-block {

      .advantages-img {
        width: 472px;
        height: 241px;
        margin-bottom: 44px;
      }
    }
  }
}

@media (max-width: 1152px) {
  .advantages-wrapper {
    .advantages-context {
      flex-direction: column;
    }

    .advantages-title {
      margin-top: 56px;
    }

    .advantages-right-block {
      flex-direction: column-reverse;

      .advantages-img {
        background-image: url('assets/advantages/hands-big.png');
        width: 95%;
        height: 560px;
        margin-bottom: 38px;
      }
    }

    .control {
      margin-bottom: 32px;
    }

  }
}

@media (max-width: 800px) {
  .advantages-wrapper {
    padding: 0 40px;

    .advantages-title {
      h4, h3 {
        text-align: center;
      }

      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 42px;
      }
    }

    .advantages-right-block {
      flex-direction: column-reverse;
      justify-content: center;

      .advantages-img {
        background-image: url('assets/advantages/hands-big.png');
        width: 90vw;
        height: 480px;
        margin-bottom: 52px;
      }
    }

    .advantages-left-block {
      margin-right: 0;
    }
  }
}

@media (max-width: 520px) {
  .advantages-wrapper {
    padding: 0 20px;

    .advantages-title {
      h4, h3 {
        text-align: center;
      }

      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 42px;
      }
    }

    .advantages-right-block {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      .advantages-img {
        height: 240px;
      }
    }

    .advantages-left-block {
      margin-right: 0;
    }
  }
}
