.form-block-wrapper {
  display: flex;
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1120px;
    margin: 0 auto;
  }

  .form-title {
    margin-top: 112px;
    margin-bottom: 48px;
    //margin-left: 8px;

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #7B7F8E;
      margin-bottom: 8px;
    }

    h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 56px;
      color: #282A32;
    }
  }

  .form-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 112px;
    background: #F5F6F8;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 48px;
    font-family: 'Montserrat', sans-serif;

    .name-label {
      margin-top: 48px;
    }

    .form-label {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #282A32;
      margin-bottom: 24px;
    }
  }

  .form-image {
    width: 50%;
    height: 652px;
    background-image: url('assets/form/form-image.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.label-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.err-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color:  #D8402E;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.2px;
  width: 100%;
  overflow: hidden;
  margin-left: 20px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.err-star {
  color:  #D8402E;
  margin-left: 4px;
}

.err-icon {
  display: block;
  min-width: 20px;
  height: 20px;
  margin-left: 8px;
  background-image: url('assets/form/info.png');
  background-repeat: no-repeat;
  background-position: center;
}

//textarea
.textarea {
  font-family: 'Montserrat', sans-serif;
  padding-top: 11px;
  height: 126px;
  font-size: 16px;
  line-height: 24px;
  color: #282A32;

  &::placeholder {
    color: #7B7F8E;
  }
}

//input

.input {
  height: 50px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #282A32;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7B7F8E;
  }
}

.input-border {
  border: 0.5px solid #A7ABB8;
}


.input, .textarea {
  padding: 21px;
  background: #FFFFFF;
  border: 0.5px solid #A7ABB8;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  outline: 0;
  outline-offset: 0;
  border: 0.5px solid #A7ABB8;

  &:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
    border: 0.5px solid #A7ABB8;
  }
}

.input-border-error {
  border: 0.5px solid #D8402E;
}

//form-btn
.form-btn {
  background: #F4D13A;
  border: 2px solid #F4D13A;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #282A32;
  padding: 12px 44px;
  cursor: pointer;
  width: 214px;

  &:hover {
    background-color: #F5F6F8;
  }

  &:active {
    background-color: #E3E5EC;
    border: 2px solid #E3E5EC;
  }
}

.btn-submitted {
  background: #36A9B7;
  border: 2px solid #36A9B7;
  color: #FFFFFF;
  //box-sizing: border-box;
  //border-radius: 4px;
  //font-weight: bold;
  //font-size: 14px;
  //line-height: 24px;
  //padding: 12px 44px;
  //cursor: pointer;

  &:hover {
    color: #282A32;
  }
}

@media (max-width: 1250px) {
  .form-block-wrapper {
    padding: 0 80px;
    .content-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 960px;
      margin: 0 auto;
    }

    .form-wrapper {
      padding: 0 64px;
    }
  }
}

@media (max-width: 1152px) {
  .form-block-wrapper {
    .content-wrapper {
      max-width: 990px;
    }

    .form-wrapper {
      margin: 0;
      padding: 0;
      flex-direction: column;
      margin-bottom: 112px;
    }

    .form {
      width: 100%;
      padding: 0 48px;
    }

    .form-image {
      width: 100%;
      height: 560px;
      background-image: url('assets/form/form-image-big.png');
      margin-top: 48px;
    }
  }
}

@media (max-width: 800px) {
  .form-block-wrapper {
    padding: 0;

    .form-title {
      text-align: center;
      margin-top: 62px;
      margin-bottom: 38px;

      h3 {
        font-weight: bold;
        font-size: 30px;
        line-height: 42px;
      }
    }

    .content-wrapper {
      max-width: 990px;
    }

    .form-wrapper {
      margin: 0;
      padding: 0;
      flex-direction: column;
      margin-bottom: 64px;
    }

    .form {
      width: 100%;
      padding: 0 48px;
      justify-content: center;
      align-items: center;

      .form-label {
        width: 100%;
      }
    }

    .form-image {
      height: 480px;
      margin-top: 44px;
    }
  }
}

@media (max-width: 420px) {
  .form-block-wrapper {

    .form {
      padding: 0 20px;

      .form-label {
        width: 100%;
      }
    }
    .form-image {
      height: 240px;
    }
  }
}

//padding: 0 20px;
