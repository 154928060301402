.footer {
  display: flex;
  flex-direction: column;
  background: #282A32;
  height: 220px;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    margin-top: 32px;
  }

  .site-link-wrapper {
    display: flex;
    width: 100%;
    max-width: 1120px;
    margin: 20px auto;
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;
    color: #f2f7fba1;
    justify-content: flex-end;
    align-items: center;

    a, a:focus, a:active {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      border-bottom: 1px solid #ffffff00;
    }

    a:hover {
      color: #fff6e691;
      border-bottom: 1px solid #fff6e691;
    }
  }

  .footer-logo {
    width: 120px;
    height: 56px;
    background-image: url('assets/footer/Horizontal.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .footer-block {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    align-items: flex-start;
    color: #FFFFFF;
    max-width: 255px;

    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 8px;
    }

    a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
  }

  .lg {
    display: none;
  }
}

@media (max-width: 1250px) {
  .footer {
    padding: 0 80px;
    min-height: 250px;

    .content-wrapper,
    .site-link-wrapper {
      max-width: 991px;
    }

    .footer-block {
      width: 235px;
    }

    .xl {
      display: none;
    }

    .lg {
      display: inline;
    }
  }
}

@media (max-width: 1152px) {
  .footer {
    height: 100%;
    min-height: 270px;

    .content-wrapper,
    .site-link-wrapper {
      max-width: 991px;
    }

    .footer-block {
      width: 25%;
    }

    .footer-logo {
      margin-right: 15px;
      min-width: 120px;
    }

    .xl {
      display: none;
    }

    .lg {
      display: inline;
    }
  }
}

@media (max-width: 800px) {
  .footer {
    height: 100%;
    padding: 0 40px;

    .content-wrapper,
    .site-link-wrapper {
      max-width: 991px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footer-block {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      text-align: center;
      margin-bottom: 30px;
    }

    .footer-logo {
      margin-right: 0;
      margin-bottom: 59px;
    }

    .xl {
      display: none;
    }

    .lg {
      display: inline;
      text-align: center;
    }
  }
}
